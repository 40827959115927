// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-stories-list-jsx": () => import("./../src/pages/stories-list.jsx" /* webpackChunkName: "component---src-pages-stories-list-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-author-category-jsx": () => import("./../src/templates/author_category.jsx" /* webpackChunkName: "component---src-templates-author-category-jsx" */),
  "component---src-templates-author-list-jsx": () => import("./../src/templates/author_list.jsx" /* webpackChunkName: "component---src-templates-author-list-jsx" */),
  "component---src-templates-basic-page-jsx": () => import("./../src/templates/basic_page.jsx" /* webpackChunkName: "component---src-templates-basic-page-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../src/templates/blog_category.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../src/templates/blog_list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-event-jsx": () => import("./../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-landing-jsx": () => import("./../src/templates/landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-story-jsx": () => import("./../src/templates/story.jsx" /* webpackChunkName: "component---src-templates-story-jsx" */),
  "component---src-templates-technology-jsx": () => import("./../src/templates/technology.jsx" /* webpackChunkName: "component---src-templates-technology-jsx" */)
}

