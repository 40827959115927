/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const scrollToElement = require('scroll-to-element');

const checkHash = (location) => {
  const { hash } = location;

  if (hash) {
    scrollToElement(hash, {
      offset: -150,
      duration: 900,
    });
  }
};

exports.onRouteUpdate = ({ location }) => {
  window.onload = () => checkHash(location);
};

exports.onInitialClientRender = () => {
  window.onload = () => setTimeout(() => checkHash(window.location), 100);
};
